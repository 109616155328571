import React, {useEffect} from 'react';
import {Link} from 'react-router-dom'
import { FaLinkedinIn } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import "./Team.css";
import NewsletterForm from './NewsletterForm';
import AOS from "aos";
import "aos/dist/aos.css";

import team_img1 from '../assets/charles.jpeg'
import team_img2 from '../assets/fred.jpeg'
import team_img3 from '../assets/winstone asugo.jpeg'
import team_img4 from '../assets/nancy.jpeg'; 


const Team = () => {
  useEffect(() =>{
    AOS.init();
    AOS.refresh();
    window.scrollTo(0,0);
  },[]);

  return (
    <div className="group-wrapper">
      <div className='contain_team'>
      <section className="group-area">    
        <p className="group-heading" data-aos="fade-up">Meet Our Team</p>
        <p className="text" data-aos="fade-up">
        At HortFarm Ltd, our team is the backbone of our mission to revolutionize agriculture. With deep expertise in farming and cutting-edge technology, we are committed to empowering farmers and ensuring the highest quality produce reaches markets around the globe. Together, we are HortFarm Ltd, committed to making a difference in the world of agriculture—one farm, one crop, one innovation at a time.
        </p>
        <div className="group-cards" data-aos="zoom-in-up">

        <div className="card-section">
    <img src={team_img1} alt="Charles Wainaina"/>
    <div className="card-info">
      <h3 className="card-heading">Charles Wainaina</h3>
      <p className="card-position">Director/CEO</p>
      <ul className="social-media-links">
        <li>
          <Link to='https://wa.me/+254701439141'><FaWhatsapp className='icon-footer'/></Link>
        </li>
        <li>
          <Link to='https://www.linkedin.com/in/charles-wainaina-15217b18?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'><FaLinkedinIn className='icon-footer'/></Link>
        </li>
      </ul>
    </div>
  </div>
  
  <div className="card-section">
    <img src={team_img2} alt="fredrick onyango ogutu"/>
    <div className="card-info">
      <h3 className="card-heading">fredrick onyango ogutu</h3>
      <p className="card-position">Advisory Board Member</p>
      <ul className="social-media-links">
        <li>
          <Link to='https://wa.me/+254786946363'><FaWhatsapp className='icon-footer'/></Link>
        </li>
        <li>
          <Link to='https://www.linkedin.com/in/fredrick-onyango-ogutu-phd-b1140126?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'><FaLinkedinIn className='icon-footer'/></Link>
        </li>               
      </ul>
    </div>
  </div>

  <div className="card-section">
    <img src={team_img4} alt="Nancy"/>
    <div className="card-info">
      <h3 className="card-heading">Nancy Cherotich</h3>
      <p className="card-position">Director</p>
      <ul className="social-media-links">
        <li>
          <Link to='https://wa.me/+254722121980'><FaWhatsapp className='icon-footer'/></Link>
        </li>
        <li>
          <Link to=''><FaLinkedinIn className='icon-footer'/></Link>
        </li>
      </ul>
    </div>
  </div>
  <div className="card-section">
    <img src={team_img3} alt="Winstone Asugo"/>
    <div className="card-info">
      <h3 className="card-heading">Winstone Asugo</h3>
      <p className="card-position">Advisory Board Member</p>
      <ul className="social-media-links">
        <li>
          <Link to='https://wa.me/+254722966865'><FaWhatsapp className='icon-footer'/></Link>
        </li>
        <li>
          <Link to='https://www.linkedin.com/in/winstone-asugo-83640239?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'><FaLinkedinIn className='icon-footer'/></Link>
        </li>
      </ul>
    </div>
  </div>
</div>

      </section>
      </div>
      <NewsletterForm/>
    </div>
  );
};

export default Team;
